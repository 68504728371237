import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js';
import axios from '../../axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode'

const LineChart = () => {
  const { location_id } = useParams();
  const lokacioni = jwt_decode(location_id)

  const [viti1, setViti1] = useState([]);
  const [viti2, setViti2] = useState([]);
  const [viti3, setViti3] = useState([]);
  const [viti4, setViti4] = useState([]);
  const [vitiMeIvjeter, setVitiMeIvjeter] = useState([]);

  const getLineChart = () => {
    axios.get(`/api/widget/statistics/survey-satisfaction/${lokacioni}`)
      .then(data => {
        setViti1(data.data[0]);
        setViti2(data.data[1]);
        setViti3(data.data[2]);
        setViti4(data?.data[3]);
        setVitiMeIvjeter(data?.data[4]);
      }).catch(err => {
        console.log(err)
      })
  }
  useEffect(() => {
    getLineChart();
  }, [])
  ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },

      title: {
        display: true,
      },
    },
  };


  const labels = [
    vitiMeIvjeter?.Year ? vitiMeIvjeter?.Year : new Date().getFullYear() - 4,
    viti4?.Year ? viti4?.Year : new Date().getFullYear() - 3,
    viti3?.Year ? viti3?.Year : new Date().getFullYear() - 2,
    viti2?.Year ? viti2?.Year : new Date().getFullYear() - 1,
    viti1?.Year ? viti1?.Year : new Date().getFullYear(),
  ];
  const actions = [
    {
      name: 'Toggle Point Style',
      handler(chart) {
        chart.options.plugins.legend.labels.usePointStyle = !chart.options.plugins.legend.labels.usePointStyle;
        chart.update();
      }
    },
  ];
  const data = {
    labels,
    datasets: [

      {
        label: '',
        pointColor: "#FFFFFF",
        borderColor: "rgba(54, 162, 235 , 0.4)",
        borderWidth: 2.5,
        pointStyle: 'circle',
        pointRadius: 5,
        pointBorderColor: '#0A5389',
        pointBackgroundColor: '#FFFFFF',
        pointBorderWidth: 1.8,
        tension: 0.1,
        data: [
          vitiMeIvjeter?.Satisfaction ? vitiMeIvjeter?.Satisfaction : 0,
          viti4?.Satisfaction ? viti4?.Satisfaction : 0,
          viti3?.Satisfaction ? viti3?.Satisfaction : 0,
          viti2?.Satisfaction ? viti2?.Satisfaction : 0,
          viti1?.Satisfaction ? viti1?.Satisfaction : 0, 5
        ]
      },

    ]
  };
  return (
    <>
      <Line options={options} data={data} />
    </>
  );
}
export default LineChart;
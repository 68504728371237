import '../dashboard/dashboard.scss';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js';
import axios from '../../axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
const BigLineChart = () => {
  const { location_id } = useParams();
  const lokacioni = jwt_decode(location_id)
  const [chartData1, setChartData1] = useState([])
  const [chartData2, setChartData2] = useState([])
  const [chartData3, setChartData3] = useState([])
  const [chartData4, setChartData4] = useState([])
  const [chartData5, setChartData5] = useState([])
  const [chartData6, setChartData6] = useState([])
  const [chartData7, setChartData7] = useState([])
  const [chartData8, setChartData8] = useState([])
  const [chartData9, setChartData9] = useState([])
  const [chartData10, setChartData10] = useState([])
  const [chartData11, setChartData11] = useState([])
  const [chartData12, setChartData12] = useState([])

  const getBigLineChart = () => {
    axios.get(`/api/widget/statistics/survey-month/${lokacioni}`)
      .then(data => {
        // console.log("chart big", data)
        setChartData1(data?.data[0]);
        setChartData2(data?.data[1]);
        setChartData3(data?.data[2]);
        setChartData4(data?.data[3]);
        setChartData5(data?.data[4]);
        setChartData6(data?.data[5]);
        setChartData7(data?.data[6]);
        setChartData8(data?.data[7]);
        setChartData9(data?.data[8]);
        setChartData10(data?.data[9]);
        setChartData11(data?.data[10]);
        setChartData12(data?.data[11]);
      })
  }
  useEffect(() => {
    getBigLineChart()
  }, [])




  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
  };

  const labels = [chartData1?.Month, chartData2?.Month, chartData3?.Month, chartData4?.Month, chartData5?.Month, chartData6?.Month, chartData7?.Month, chartData8?.Month, chartData9?.Month, chartData10?.Month, chartData11?.Month, chartData12?.Month];

  const data = {
    labels,
    datasets: [{
      label: 'Surveys Taken',
      borderColor: "rgb(10,83,137)",
      pointStyle: 'triangle',
      tension: 0.01,
      data: [
        // this data when the final deployment is done should be without this validation but should only be listed as below
        // chartData1?.total, 
        // chartData2?.total, 
        // chartData3?.total etc. etc. 

        chartData1?.total,
        chartData2?.total,
        chartData3?.total,
        chartData4?.total,
        chartData5?.total,
        chartData6?.total,
        chartData7?.total,
        chartData8?.total,
        chartData9?.total,
        chartData10?.total,
        chartData11?.total,
        chartData12?.total
      ]
    }]
  };
  return (
    <>
      <Line className='' options={options} data={data} />
    </>
  );
}
export default BigLineChart;
import React from 'react'
import { useState, useEffect } from "react";
import axios from "../../axios";
import "./dashboard.scss"
import BarChart from '../barChart/BarChart';
import LineChart from '../lineChart/LineChart';
import BigLineChart from '../bigLineChart/BigLineChart';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import translations from "../../data/labels.json"

const Dashboard = () => {
  const { location_id } = useParams();
  const { lang } = useParams();
  const lokacioni = jwt_decode(location_id)

  const [lineChartrate, setLineChartRate] = useState([])
  const getLineChart = () => {
    axios.get(`api/widget/statistics/survey-satisfaction/${lokacioni}`)
      .then(data => {
        // console.log("data", data)
        setLineChartRate(data.data[0])
      }).catch(err => {
        console.log(err)
      })
  }
  useEffect(() => {
    getLineChart();
  }, [])

  return (
    <div className="wrapper widgetBody">
      <header className="App-header mb-2">
        <h1 style={{ color: "#0a5389", fontWeight: "700", fontSize: "60px" }} dangerouslySetInnerHTML={{
          __html:


            lang == "al" ?
              translations?.find((el) => el.key == "AnalizatStatistikoreLabel").content_al.split(' ')[0] :
              lang == "en" ?
                translations?.find((el) => el.key == "AnalizatStatistikoreLabel").content_en.split(' ')[0]
                :
                translations?.find((el) => el.key == "AnalizatStatistikoreLabel").content_sr.split(' ')[0]

        }}>


        </h1>
        <h1 className='poshte' style={{ color: "#0a5389" }} >
          &nbsp;{
            lang == "al" ?
              translations?.find((el) => el.key == "AnalizatStatistikoreLabel").content_al.split(' ')[1] :
              lang == "en" ?
                translations?.find((el) => el.key == "AnalizatStatistikoreLabel").content_en.split(' ')[1]
                :
                translations?.find((el) => el.key == "AnalizatStatistikoreLabel").content_sr.split(' ')[1]

            /* {lang == "en" ? "Analysis"
              : lang == "al" ? "Statistikore"
                : "Статистичка"
            } */
          }
        </h1>
      </header>
      <div className="row gap-5">
        <div className="blloku col-4 gradientBlock pt-2 pb-2">
          <BarChart />
        </div>
        <div className="col-4 gradientBlock pt-2 pb-2">
          <LineChart />
        </div>
        <div className="col pt-2 pb-2 gradientBlock">
          <div className="col mt-3">
            <p style={{ fontSize: "20px", color: "#0a5389" }}
              dangerouslySetInnerHTML={{
                __html:


                  lang == "al" ?
                    translations?.find((el) => el.key == "Vlerësimi-në-kohë-reale-Mesatarja-e-kënaqësisë-LABEL").content_al :
                    lang == "en" ?
                      translations?.find((el) => el.key == "Vlerësimi-në-kohë-reale-Mesatarja-e-kënaqësisë-LABEL").content_en
                      :
                      translations?.find((el) => el.key == "Vlerësimi-në-kohë-reale-Mesatarja-e-kënaqësisë-LABEL").content_sr

              }}
            >

              {/* {lang === "en" ? (
                <>
                  Real Time <br />
                  Satisfaction Average <br />
                  rating in {lineChartrate?.Year} <br />
                </>
              ) : lang === "al" ? (
                <>
                  Vlerësimi në kohë reale <br />
                  Mesatarja e kënaqësisë <br />
                  {lineChartrate?.Year} <br />
                </>
              ) : (
                <>
                  У реалном времену <br />
                  Просечно задовољство <br />
                  оцена у {lineChartrate?.Year} <br />
                </>
              )} */}
            </p>
            <div className="d-flex justify-content-center">
              <h4 className='pt-3' style={{ fontSize: "65px", fontWeight: "600", color: "#0a5389" }}>{parseFloat(lineChartrate?.Satisfaction).toFixed(2)}</h4>
              <h1 style={{ fontSize: "87px", fontWeight: "700", color: "#0a5389" }}>/5</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="row gap-5 mt-5">
        <div className="col-4 pt-4 blocks">
          <p>
            {
              lang == "al" ?
                translations?.find((el) => el.key == "NUMRI-TOTALI-I-ANKETAVE-GJATË-3-VJETEVE-TË-KALUARA-LABEL").content_al :
                lang == "en" ?
                  translations?.find((el) => el.key == "NUMRI-TOTALI-I-ANKETAVE-GJATË-3-VJETEVE-TË-KALUARA-LABEL").content_en
                  :
                  translations?.find((el) => el.key == "NUMRI-TOTALI-I-ANKETAVE-GJATË-3-VJETEVE-TË-KALUARA-LABEL").content_sr


              // lang == "al" ?
              // "NUMRI TOTALI I ANKETAVE GJATË 3 VJETEVE TË KALUARA" :
              // lang == "en" ? "TOTAL NUMBER OF SURVEYS OVER THE PAST 3 YEARS" :
              // "УКУПАН БРОЈ ИСТРАЖИВАЊА У ПОСЛЕДЊЕ 3 ГОДИНЕ"
            }
          </p>
        </div>
        <div className="col-4 pt-4 blocks">
          <p>
            {
              lang == "al" ?
                translations?.find((el) => el.key == "VLERËSIMI-MESATAR-I-KËNAQËSISË-NË-5-VITET-E-FUNDIT-LABEL").content_al :
                lang == "en" ?
                  translations?.find((el) => el.key == "VLERËSIMI-MESATAR-I-KËNAQËSISË-NË-5-VITET-E-FUNDIT-LABEL").content_en
                  :
                  translations?.find((el) => el.key == "VLERËSIMI-MESATAR-I-KËNAQËSISË-NË-5-VITET-E-FUNDIT-LABEL").content_sr

              // lang == "al" ?
              //   "VLERËSIMI MESATAR I KËNAQËSISË NË 5 VITET E FUNDIT" :
              //   lang == "en" ? " AVERAGE SATISFACTION RATING OVER THE PAST 5 YEARS" :
              //     "ПРОСЕЧНА ОЦЕНА ЗАДОВОЉСТВА У ПОСЛЕДЊИХ 5 ГОДИНА"
            }
          </p>
        </div>
        <div className="col pt-4 blocks">
          <p >
            {
              lang == "al" ?
                translations?.find((el) => el.key == "VLERËSIMI-MESATAR-I-VITIT-AKTUAL-I-LLOGARITUR-AUTOMATIKË-LABEL").content_al :
                lang == "en" ?
                  translations?.find((el) => el.key == "VLERËSIMI-MESATAR-I-VITIT-AKTUAL-I-LLOGARITUR-AUTOMATIKË-LABEL").content_en
                  :
                  translations?.find((el) => el.key == "VLERËSIMI-MESATAR-I-VITIT-AKTUAL-I-LLOGARITUR-AUTOMATIKË-LABEL").content_sr
              // lang == "al" ? "VLERËSIMI MESATAR I VITIT AKTUAL I LLOGARITUR AUTOMATIKË"
              //   : lang == "en" ? " THE AVERAGE RATING OF THE CURRENT YEAR CALCULATED AUTOMATICALY"
              //     : "ПРОСЕЧНА ОЦЕНА ТЕКУЋЕ ГОДИНЕ ИЗРАЧУНАНА АУТОМАТСКИ"
            }
          </p>
        </div>
      </div>
      <div className="row mt-5 gap-5">
        <div className="biglin gradientBlock ">
          <BigLineChart />
        </div>
        <div className="col blocks boksiFundit" >
          <p className='shabanProlluzha1'
            dangerouslySetInnerHTML={{
              __html:
                lang == "al" ?
                  translations?.find((el) => el.key == "NUMRI-TOTALI-I-ANKETAVE-PËR-MUAJ-LLOGARITET-PËR-VITIN-AKTUAL-hënor-LABEL").content_al :
                  lang == "en" ?
                    translations?.find((el) => el.key == "NUMRI-TOTALI-I-ANKETAVE-PËR-MUAJ-LLOGARITET-PËR-VITIN-AKTUAL-hënor-LABEL").content_en
                    :
                    translations?.find((el) => el.key == "NUMRI-TOTALI-I-ANKETAVE-PËR-MUAJ-LLOGARITET-PËR-VITIN-AKTUAL-hënor-LABEL").content_sr

              // lang == "al" ? "NUMRI TOTALI I ANKETAVE PËR <br /> MUAJ LLOGARITET PËR <br /> VITIN AKTUAL hënor" :
              //   lang == "en" ? "TOTAL NUMBER OF SURVEYS PER <br /> MONTH IS CALCULATED FOR <br /> THE CURRENT LUNAR YEAR" :
              //     "УКУПАН БРОЈ ИСТРАЖИВАЊА ПО <br/> МЕСЕЦУ СЕ ОБРАЧУНА ЗА <br/> ТЕКУЋУ ЛУНАРНУ ГОДИНУ"
            }}
          >



          </p>
          <p className='shabanProlluzha'
            dangerouslySetInnerHTML={{
              __html:

                lang == "al" ?
                  translations?.find((el) => el.key == "Të-gjithë-grafikët-e-paraqitur-në-këtë-pamje-paraprake-të-analizës-statistikore-janë-përditësuar-në-kohë-reale-LABEL").content_al :
                  lang == "en" ?
                    translations?.find((el) => el.key == "Të-gjithë-grafikët-e-paraqitur-në-këtë-pamje-paraprake-të-analizës-statistikore-janë-përditësuar-në-kohë-reale-LABEL").content_en
                    :
                    translations?.find((el) => el.key == "Të-gjithë-grafikët-e-paraqitur-në-këtë-pamje-paraprake-të-analizës-statistikore-janë-përditësuar-në-kohë-reale-LABEL").content_sr
              // lang == "al" ? "Të gjithë grafikët e paraqitur në këtë pamje paraprake të analizës statistikore <br /> janë <br /> përditësuar në kohë reale" :
              //   lang == "en" ? "All the graphs presented in this <br /> statistical analysis preview are <br /> updated in realtime" :
              //     "Сви графикони представљени у овом <br/> прегледу статистичке анализе се <br/> ажурирају у реалном времену"
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default Dashboard;
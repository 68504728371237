import React from "react";
import "./GroupLocationDashboard.scss"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from "../../axios";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import jwt_decode from 'jwt-decode'
import translations from "../../data/labels.json"
import ChartDataLabels from 'chartjs-plugin-datalabels';


const costumToolTip = (tooltipItems) => {
    return null;
};
const GroupLocationDashboard = () => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        PointElement,
        ChartDataLabels
    );
    const { location_id } = useParams();
    const lokacioni = jwt_decode(location_id)
    const [groupdata, setGroupData] = useState([]);
    const { lang } = useParams();

    const GroupBarChart = () => {
        axios.get(`/api/widget/group_statistics/survey-satisfaction/${lokacioni}`)
            .then(data => {
                setGroupData(data.data)
            }).catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        GroupBarChart();
    }, [])


    const processedData = groupdata.map(el => {
        const value = el.Satisfaction.toFixed(1);
        return value !== "0.0" ? value : "0.0";
    });

    const labels = groupdata.map(el => {
        return (
            lang == "al" ? el?.name_al :
                lang == "en" ? el?.name_en :
                    el?.name_sr
        )
    });
    const backgroundColors = [
        '#96a7d2', '#629dd1', '#629dd1', "#629dd1", "#4762a7", "#194c80", "#3c485a",
        '#96a7d2', '#629dd1', '#629dd1', "#629dd1", "#4762a7", "#194c80", "#3c485a",
        '#96a7d2', '#629dd1', '#629dd1', "#629dd1", "#4762a7", "#194c80", "#3c485a",
        '#96a7d2', '#629dd1', '#629dd1', "#629dd1", "#4762a7", "#194c80", "#3c485a",
    ];

    const processedDataWithZeroBars = processedData.map(value => {
        // if (value === "0.0") {
        //     // Add a data point for a bar from 0 to 1 for values of 0.0
        //     return [0, 1];
        // } else {
        return value;
        // }
    });

    const bardata = {
        labels: labels,
        datasets: [
            {
                label: null,
                data: processedDataWithZeroBars,
                backgroundColor: processedData.map((value, index) => {
                    return value !== "0.0" ? backgroundColors[index] : 'lightgrey';
                }),
            },
        ],
    };

    const customToolTip = (tooltipItems) => {
        const value = tooltipItems[0]?.parsed?.y;
        if (Array.isArray(value) && value.length === 2 && value[0] === 0 && value[1] === 1) {
            return 'No Ratings Yet!';// Hide label if value is [0, 1]
        } else {
            return null;
        }
    };


    const baroptions = {
        plugins: {
            title: {
                display: true,
            },
            legend: {
                display: false,
                position: "bottom"
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    footer: customToolTip,
                }
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                font: function (value) {
                    let shouldRotate = value.dataset.data[value.dataIndex]
                    if (shouldRotate == 0.0) {
                        return {
                            // weight: "bold",
                            // size: "30px",
                        };
                    }
                },
                formatter: function (value, context) {
                    let rotation = 0; // Default rotation angle
                    if (value === "0.0") {
                        value = lang === "al" ?
                            translations?.find((el) => el.key === "nukKaVleresim-LABEL").content_al :
                            lang === "en" ?
                                translations?.find((el) => el.key === "nukKaVleresim-LABEL").content_en
                                :
                                translations?.find((el) => el.key === "nukKaVleresim-LABEL").content_sr;
                        rotation = -Math.PI / 2; // Rotate text 90 degrees counter-clockwise
                    } else if (value === 0) {
                        value = ''; // Return empty string for 0 values
                    }
                    return String(value);
                },
                rotation: function (value) {
                    console.log("function (value, context) {", value)
                    let shouldRotate = value.dataset.data[value.dataIndex]
                    console.log("shouldRotate", shouldRotate)
                    if (shouldRotate == 0.0) {
                        return -Math.PI - 87
                    }
                }
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                min: 0,
                max: 5,
            },
        },
    };





    // let test = groupdata.filter((el) => {
    //     if (el !== null) {
    //         let data = [el.name_al];

    //     } else {
    //         console.log('skam')

    //     }
    // });
    // const labels = groupdata.map(el => {
    //     return (
    //         lang == "al" ? el?.name_al :
    //             lang == "en" ? el?.name_en :
    //                 el?.name_sr
    //     )
    // });

    // const data = groupdata.map(el => { return el.Satisfaction.toFixed(1) });

    // const bardata = {
    //     labels: labels,
    //     datasets: [
    //         {
    //             label: null,
    //             data: data,
    //             backgroundColor: ['#96a7d2', '#629dd1', '#629dd1', "#629dd1", "#4762a7", "#194c80", "#3c485a"],
    //         },

    //     ],
    // };

    return (
        <>
            <header className="App-header ms-4">
                <h1 style={{ color: "#0a5389", fontWeight: "700", fontSize: "40px" }}>
                    {
                        lang == "al" ?
                            translations?.find((el) => el.key == "Analiza-statistikore-në-grup-LABEL").content_al :
                            lang == "en" ?
                                translations?.find((el) => el.key == "Analiza-statistikore-në-grup-LABEL").content_en
                                :
                                translations?.find((el) => el.key == "Analiza-statistikore-në-grup-LABEL").content_sr
                    }
                </h1>
                {/* <h1 className='poshte' style={{ color: "#0a5389" }}> Analysis</h1> */}
            </header>
            <p className='mt-3 poshte' style={{ color: "#0a5389", fontWeight: "bold" }}>
                {
                    lang == "al" ?
                        translations?.find((el) => el.key == "Kënaqësia-mesatare-e-vendndodhjeve-LABEL").content_al :
                        lang == "en" ?
                            translations?.find((el) => el.key == "Kënaqësia-mesatare-e-vendndodhjeve-LABEL").content_en
                            :
                            translations?.find((el) => el.key == "Kënaqësia-mesatare-e-vendndodhjeve-LABEL").content_sr
                }
            </p>
            <div className="mt-2">

                <p className='mt-3 poshte' style={{ color: "#0a5389", fontWeight: "bold" }}>
                    {
                        lang == "al" ?
                            translations?.find((el) => el.key == "Numrat-reflektues-LABEL").content_al :
                            lang == "en" ?
                                translations?.find((el) => el.key == "Numrat-reflektues-LABEL").content_en
                                :
                                translations?.find((el) => el.key == "Numrat-reflektues-LABEL").content_sr
                    }
                </p>

                <p
                    className='mt-2'
                    style={{ color: "#0a5389", }}
                    dangerouslySetInnerHTML={{
                        __html: lang === "al" ?
                            translations?.find((el) => el.key === "Numrat-vlersues-LABEL").content_al :
                            lang === "en" ?
                                translations?.find((el) => el.key === "Numrat-vlersues-LABEL").content_en
                                :
                                translations?.find((el) => el.key === "Numrat-vlersues-LABEL").content_sr
                    }}
                />
            </div>
            <div className="mx-auto col-sm-10">
                <Bar options={baroptions} data={bardata} />
            </div>
            {/* <div className="mt-4 col-lg-4 col-md-8 col-10 text-start mx-auto">

            <p className='mt-3 poshte' style={{ color: "#0a5389", fontWeight: "bold" }}>
                {
                    lang == "al" ?
                        translations?.find((el) => el.key == "Numrat-reflektues-LABEL").content_al :
                        lang == "en" ?
                            translations?.find((el) => el.key == "Numrat-reflektues-LABEL").content_en
                            :
                            translations?.find((el) => el.key == "Numrat-reflektues-LABEL").content_sr
                }
            </p>

            <p
                className='mt-2'
                style={{ color: "#0a5389", fontWeight: "bold" }}
                dangerouslySetInnerHTML={{
                    __html: lang === "al" ?
                        translations?.find((el) => el.key === "Numrat-vlersues-LABEL").content_al :
                        lang === "en" ?
                            translations?.find((el) => el.key === "Numrat-vlersues-LABEL").content_en
                            :
                            translations?.find((el) => el.key === "Numrat-vlersues-LABEL").content_sr
                }}
            />
            </div> */}

        </>
    );
}
export default GroupLocationDashboard;
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from '../../axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode'

const BarChart = () => {
  const { location_id } = useParams();
  const lokacioni = jwt_decode(location_id)

  const [surveys1, setSurveys1] = useState([]);
  const [surveys2, setSurveys2] = useState([]);
  const [surveys3, setSurveys3] = useState([]);

  const getSurveys = () => {
    axios.get(`/api/widget/statistics/survey-stats/${lokacioni}`)
      .then(data => {
        // console.log("BarChartData", data.data)
        setSurveys1(data?.data[0])
        setSurveys2(data?.data[1])
        setSurveys3(data?.data[2])
      }).catch(err => {
        console.log(err)
      })
  }
  useEffect(() => {
    getSurveys();
  }, [])


  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement
  );
  const baroptions = {
    plugins: {
      title: {
        display: true,
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  // console.log("surveys1", surveys2)
  const labels = [
    surveys3?.date ? surveys3?.date && surveys3?.date === null ? surveys3?.date :surveys3?.date : new Date().getFullYear() - 2,
    surveys2?.date ? surveys2?.date && surveys2?.date === null ?  surveys2?.date :surveys2?.date : new Date().getFullYear() - 1,
    surveys1?.date,
  ];
  const bardata = {
    labels,
    datasets: [
      {
        label: null,
        data: [ surveys3?.total ,surveys2?.total, surveys1?.total],
        backgroundColor: ['rgba(54, 162, 235 , 0.4)'],
      },

    ],
  };
  
  return (
    <>
      <Bar options={baroptions} data={bardata} className="h-100 pb-2" />
    </>
  );
}
export default BarChart;
import React from 'react'
import './loader.css'
const loader = () => {
    return (
        <>
            <div class="container">
                <div class="logo"><i class="fab fa-court" id="windows"></i></div>
                <div class="loader">
                    <div class="bg"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
            </div>
            <p>Ju lutem prisni !</p>

        </>

    )
}
export default loader;

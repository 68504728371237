import './App.css';
import Loader from './components/loader/loader';
import Dashboard from './containers/dashboard/dashboard';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import GroupLocationDashboard from './containers/groupLocationDashboard/GroupLocationDashboard';
import jwt_decode from 'jwt-decode'

const data = <Dashboard />;
// console.log(data)
function App() {

  let type = window.location.pathname.split('/')[2];
  const tipiWidgetit = jwt_decode(type)
  // console.log(tipiWidgetit)

  if (data) {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            {
              tipiWidgetit === "1" ?
                <Route path="/:lang/:type_id/:location_id" element={<Dashboard />} />
                :
                tipiWidgetit === "2" ?
                  <Route path="/:lang/:type_id/:location_id" element={<GroupLocationDashboard />} />
                  :
                  <Loader />
            }
          </Routes>
        </BrowserRouter>
      </div>
    );
  } else {
    return <Loader />
  }

}

export default App;

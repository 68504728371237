import axios from "axios";

const defaultInstance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
    //   Authorization: "Bearer " + token,
      
    },
  });
  
  export default defaultInstance;